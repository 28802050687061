// Define FVH color palette to override default Bootstrap colors:

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #9FC5E8;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #d70074;
$orange:  #FF5000;
$yellow:  #Ffd650;
$green:   #B6D7A8;
$teal:    #009E92;
$cyan:    #00a4cc;

// stylelint-disable-next-line scss/dollar-variable-default
$colors: (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
);

$primary:       $orange;
$secondary:     $teal;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $white;
$dark:          $black;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "green":      $green,
    "yellow":     $yellow,
    "blue":       $blue,
);

@import "../node_modules/bootstrap/scss/bootstrap";


// Misc style corrections for our use cases:
body {
  background-color: #f7f9fc;
}

a, button {
  background: none;
  border: none;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

button.nav-link {
  display: inline-block;
}

button.nav-link:focus {
  outline: none;
}

.container {
  max-width: 800px;
}

.modal {
  display: block
}

.modal-header-actions {
  position: absolute;
  right: 48px;
  top: 8px;
}

.btn-compact {
  padding: 0.1rem 0.5rem;
}

.btn-compact.btn-sm .material-icons {
  font-size: 20px;
}

.btn-outline-primary:active, .btn-outline-primary:hover,
.btn-outline-dark:active, .btn-outline-dark:hover,
.btn-outline-secondary:active, .btn-outline-secondary:hover {
  background-color: white !important;
}

.btn-outline-primary:active, .btn-outline-primary:hover {
  color: $primary !important;
}

.btn-outline-secondary:active, .btn-outline-secondary:hover {
  color: $secondary !important;
}

.btn-outline-dark:active, .btn-outline-dark:hover {
  color: $dark !important;
}

.material-icons {
  line-height: 0.8;
}

.btn-outline-primary:hover, .btn-outline-primary:active {
    color: $primary;
    background-color: rgba(255, 80, 0, 0.1);
}

form.compact {
  .form-group {
    position: relative;
    margin-bottom: 0.5rem;

    .control-label {
      width: 40%
    }
    .form-control, .checkbox {
      display: inline-block;
      width: 55%;
    }
    .checkbox input {
      margin-right: 0.5rem;
    }
    .field-description {
      position: absolute;
      top: 1.8rem;
      left: 0;
      font-size: 75%;
      color: #999;
      max-width: 40%;
      line-height: 15px;
    }
    .field-radio-group {
      padding-left: 40%;
      margin-top: -2rem;

      .radio {
        display: inline-block;
        padding-right: 1rem;

        input {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.btn-discrete {
  opacity: 0.2;
}

.btn-discrete:hover {
  opacity: 1;
}

.btn-white {
  background-color: white;
}

div.leaflet-top, div.leaflet-bottom {
  z-index: 400;
}

.map-tools {
  z-index: 500;
  right: 0;
  background: rgba(255, 255, 255, 0.4)
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
